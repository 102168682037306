import React from 'react';
import { Button, Card } from 'react-bootstrap';

//styles
import './todayMedicAppointmentComponent.css';
import * as MedicalAppoinmentStore from '../redux/reducer/medicalAppointmentReducer';

//images
import clock from '../images/clock.svg';
import { DoctorNameComponent } from './doctorNameComponent';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';
import Moment from 'react-moment';
import { AppState } from '../redux/reducer';
import { connect, useDispatch } from 'react-redux';

export interface IProps {
  appointment: MedicalAppointment;
}

export const TodayMedicAppointmentComponent = (props: IProps) => {
  const dispatch = useDispatch();

  const handlerButtonStartAppointment = () => {
    dispatch(
      mapDispatchToProps.ConfirmMedicalAppointments(props.appointment.id)
    );
    window.open(
      'https://meet.test.cinme.com.ar/' + props.appointment.appointmentCallId,
      '_tab'
    );
  };

  const handlerButtonCancelarAppointment = () => {
    dispatch(
      mapDispatchToProps.CancelMedicalAppointments(props.appointment.id)
    );
  };

  return (
    <Card>
      <Card.Header className="headerblue">
        <label>
          <img src={clock} alt="" />{' '}
          <Moment format="dddd DD MMM yyyy HH:mm ">
            {props.appointment.appointmentDateStart}
          </Moment>
        </label>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <DoctorNameComponent appointment={props.appointment} />
        </Card.Text>
        <div className="appointmentActions">
          <Button variant="danger" onClick={handlerButtonCancelarAppointment}>
            Cancelar Consulta
          </Button>
          <Button variant="success" onClick={handlerButtonStartAppointment}>
            Iniciar Consulta
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalAppointments
});

const mapDispatchToProps = {
  ...MedicalAppoinmentStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TodayMedicAppointmentComponent as any);
function userdispatch() {
  throw new Error('Function not implemented.');
}
