import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import './App.css';

//pages
import { MedicalAppointmentPage } from './pages/appointments/medicalappointments.page';
import { LoginPage } from './pages/login/login.page';
import { VerificationCodePage } from './pages/login/verificationcode.page';

initializeIcons();

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="" element={<LoginPage />} />
      <Route
        path="/:patientDocument/verification"
        element={<VerificationCodePage />}
      />
      <Route
        path="patient/:patientId/appointments/"
        element={<MedicalAppointmentPage />}
      />
    </Routes>
  </BrowserRouter>
);

export default App;
