import React from 'react';
import DoctorIcon from '../images/doctor-svgrepo-com.svg';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';

//styles
import './doctorNameComponent.css';

export interface IProps {
  appointment: MedicalAppointment;
}

export const DoctorNameComponent = (props: IProps) => {
  return (
    <p className="doctorName">
      <img src={DoctorIcon} alt=""></img>
      <label>
        <strong>{props.appointment.specialtyDescription}</strong>
        Dr.{' '}
        {props.appointment.doctor.name + ' ' + props.appointment.doctor.surName}
      </label>
    </p>
  );
};
