import { DefaultButton } from '@fluentui/react';

import { useSelector, useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../redux/reducer';
import * as PatientStore from '../redux/reducer/patientReducer';

export const UserInfo = () => {
  const CurrentPatient = useSelector((state: AppState) => state.Patient);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleExit = () => {
    dispatch(mapDispatchToProps.PatientExitAction());
    navigate('/');
  };

  return (
    <div className="UserInfo">
      <DefaultButton text="Salir" onClick={handleExit} />
      {/* <img
        className="no_show_mobile"
        src={userIconChecked}
        alt="user icon"
      ></img>
      <label>
        {CurrentPatient?.Patient?.nombre +
          ' ' +
          CurrentPatient?.Patient?.apellido +
          ' - ' +
          CurrentPatient?.Patient?.documento}
      </label> */}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.Patient
});

const mapDispatchToProps = {
  ...PatientStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo as any);
