import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Header } from '../../commons/pageHeaderComponent';
import { NextMedicAppointmentComponent } from '../../components/nextMedicalAppointmentComponent';
import { TodayMedicAppointmentComponent } from '../../components/todayMedicAppointmentComponent';
import { AppState } from '../../redux/reducer';
import '../appointments/medicalappointments.css';
import * as MedicalAppoinmentStore from '../../redux/reducer/medicalAppointmentReducer';
import * as PatientStore from '../../redux/reducer/patientReducer';
import { Navigate, useNavigate } from 'react-router-dom';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';
import { useParams } from 'react-router-dom';
import noAppointments from '../../images/noAppointment.png';
import { IIconProps, Label, PrimaryButton, Spinner } from '@fluentui/react';
import { AppointmentFormComponent } from '../../components/Appointment.Form/Appointment.Form.Component';
import { useBoolean } from '@fluentui/react-hooks';

export const MedicalAppointmentPage = () => {
  const CurrentPatient = useSelector((state: AppState) => state.Patient);
  const MyAppointments = useSelector(
    (state: AppState) => state.MedicalAppointments
  );
  const [TodayMedicalAppointments, setTodayMedicalAppointments] =
    useState<MedicalAppointment[]>();
  const [NextMedicalAppointments, setNextMedicalAppointments] =
    useState<MedicalAppointment[]>();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const { patientId } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(mapDispatchToProps.VerifyAuthenticationToken());
  }, []);

  useEffect(() => {
    if (patientId && CurrentPatient?.Token) {
    }
    dispatch(mapDispatchToProps.RequestMedicalAppointments(Number(patientId)));
  }, [CurrentPatient?.Token]);

  useEffect(() => {
    if (patientId) {
      dispatch(mapDispatchToProps.RequestPatientById(Number(patientId)));
    }
  }, []);

  useEffect(() => {
    if (MyAppointments?.MedicalAppointments) {
      setTodayMedicalAppointments(
        MyAppointments.MedicalAppointments?.filter(
          (appointment: MedicalAppointment) =>
            new Date(appointment.appointmentDateStart).getDate() ===
            new Date().getDate()
        )
      );
      setNextMedicalAppointments(
        MyAppointments.MedicalAppointments?.filter(
          (appointment: MedicalAppointment) =>
            new Date(appointment.appointmentDateStart).getDate() >
            new Date().getDate()
        )
      );
    }
  }, [MyAppointments?.MedicalAppointments]);

  const HandleAddAppointmentClick = () => {
    openPanel();
  };

  return (
    <>
      <Header Title="Mis turnos - Telemedicina" />
      <div className="misTurnosPage">
        <div className="PageHeader">
          <h1>
            <span>
              <strong>Hola</strong> {CurrentPatient?.Patient?.nombre}!
            </span>
          </h1>
          <PrimaryButton
            text="Nuevo Turno"
            iconProps={addIcon}
            onClick={HandleAddAppointmentClick}
            allowDisabledFocus
          />
        </div>

        <div className="TurnosList">
          <div className="List">
            <h5>
              <strong>Turnos para hoy</strong>
            </h5>
            {TodayMedicalAppointments?.map(
              (appointment: MedicalAppointment) => (
                <TodayMedicAppointmentComponent appointment={appointment} />
              )
            )}
            {TodayMedicalAppointments?.length === 0 &&
              !MyAppointments?.loading && (
                <div className="noAppoinments">
                  <img
                    src={noAppointments}
                    className="relaxImage"
                    alt="no hay turnos para hoy"
                  />
                  <Label>No tiene agendado turnos para el día de hoy</Label>
                </div>
              )}
            {MyAppointments?.loading && (
              <div className="noAppoinments">
                <Spinner label="Cargando..." />
              </div>
            )}
          </div>
          <div className="List large">
            <h5>
              <strong>Próximos turnos</strong>
            </h5>
            {NextMedicalAppointments?.map((appointment: MedicalAppointment) => (
              <NextMedicAppointmentComponent appointment={appointment} />
            ))}
          </div>
        </div>
      </div>
      <AppointmentFormComponent
        isOpen={isOpen}
        dismissPanel={dismissPanel}
        openPanel={openPanel}
      />
    </>
  );
};

const addIcon: IIconProps = { iconName: 'Add' };

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalAppointments
});

const mapDispatchToProps = {
  ...PatientStore.actionCreators,
  ...MedicalAppoinmentStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalAppointmentPage as any);
