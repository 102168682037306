import { DoctorAvailability } from './../../models/DoctorAvailabilityModel';
import { Doctor } from './../../models/DoctorModel';
import * as DoctorActions from './../actions/doctorActions';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import moment from 'moment';
import { Result } from '../../models/CommonModels';
import { ErrorType } from '../../models/FetchErrorModel';

type KnownAction =
  | DoctorActions.RequestDoctorsAction
  | DoctorActions.ReceiveDoctorsAction
  | DoctorActions.RequestDoctorAction
  | DoctorActions.ReceiveDoctorAction
  | DoctorActions.ReceiveEmptyDoctorAction
  | DoctorActions.RequestDoctorAvailability
  | DoctorActions.ReceiveDoctorAvailability
  | DoctorActions.RequestSpecialtyAvailability
  | DoctorActions.ReceiveSpecialtyAvailability
  | DoctorActions.OperationFailAction
  | DoctorActions.RequestSpecialtiesAction
  | DoctorActions.ReceiveSpecialtiesAction;

export interface DoctorState {
  readonly loading: boolean;
  readonly Doctor: Doctor | undefined;
  readonly DoctorExist: boolean | undefined;
  readonly Doctors: Doctor[];
  readonly SpecialtyAvailability: DoctorAvailability[];
  readonly Specialties: string[];
  readonly DoctorAvailability: DoctorAvailability | undefined;
  readonly OperationError: ErrorType[];
}

const initialPatientState: DoctorState = {
  loading: false,
  Doctor: undefined,
  DoctorExist: undefined,
  Doctors: [],
  Specialties: [],
  SpecialtyAvailability: [],
  DoctorAvailability: undefined,
  OperationError: []
};

export const reducer: Reducer<DoctorState> = (
  state: DoctorState | undefined,
  incomingAction: Action
): DoctorState => {
  if (state === undefined) {
    return initialPatientState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_DOCTORS': {
      return {
        ...state,
        loading: true
      };
    }
    case 'RECEIVE_DOCTORS': {
      return {
        ...state,
        Doctors: action.Doctors,
        loading: false,
        OperationError: []
      };
    }
    case 'REQUEST_DOCTOR': {
      return {
        ...state,
        loading: true
      };
    }
    case 'RECEIVE_DOCTOR': {
      return {
        ...state,
        Doctor: action.Doctor,
        loading: false,
        OperationError: []
      };
    }
    case 'RECEIVE_EMPTY_DOCTOR': {
      return {
        ...state,
        Doctor: undefined,
        loading: false,
        DoctorExist: false,
        OperationError: []
      };
    }
    case 'REQUEST_DOCTOR_AVAILABILITY': {
      return {
        ...state,
        loading: true
      };
    }
    case 'RECEIVE_DOCTOR_AVAILABILITY': {
      return {
        ...state,
        DoctorAvailability: action.DoctorAvailability,
        loading: false,
        OperationError: []
      };
    }
    case 'REQUEST_SPECIALTY_AVAILABILITY': {
      return {
        ...state,
        loading: true,
        OperationError: []
      };
    }
    case 'RECEIVE_SPECIALTY_AVAILABILITY': {
      return {
        ...state,
        SpecialtyAvailability: action.SpecialtyAvailability,
        loading: false,
        OperationError: []
      };
    }
    case 'REQUEST_SPECIALTIES': {
      return {
        ...state
      };
    }
    case 'RECEIVE_SPECIALTIES': {
      return {
        ...state,
        Specialties: action.Specialties,
        OperationError: []
      };
    }
    case 'OPERATION_FAIL': {
      return {
        ...state,
        OperationError: [...state.OperationError, action.Error]
      };
    }
  }

  return state;
};

export const actionCreators = {
  RequestDoctors:
    (SearchCriteria: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_DOCTORS', SearchCriteria: SearchCriteria });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/medicos/Medicos?SearchText=' +
            SearchCriteria
        )
          .then((response) => {
            switch (response.status) {
              case 204:
                return undefined;
              case 200:
                return response.json() as Promise<Doctor[]>;
              default:
                return undefined;
            }
          })
          .then((data: Doctor[] | undefined) => {
            if (data !== undefined)
              dispatch({ type: 'RECEIVE_DOCTORS', Doctors: data });
          });
      }
    },
  RequestDoctorById:
    (doctorId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_DOCTOR', Id: doctorId });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/medicos/Medicos' + doctorId
        )
          .then((response) => response.json() as Promise<Doctor>)
          .then((data: Doctor) => {
            dispatch({ type: 'RECEIVE_DOCTOR', Doctor: data });
          });
      }
    },
  RequestSpecialties:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_SPECIALTIES' });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/medicos/Medicos/specialties'
        )
          .then((response) => response.json() as Promise<string[]>)
          .then((data: string[]) => {
            dispatch({ type: 'RECEIVE_SPECIALTIES', Specialties: data });
          });
      }
    },
  RequestDoctorCurrentAvailability:
    (
      id: number,
      dateRangeInit: Date,
      dateRangeEnd: Date,
      medicalInsuranceId: number,
      medicalInsurancePlanId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'REQUEST_DOCTOR_AVAILABILITY',
          id: id,
          dateRangeInit: dateRangeInit,
          dateRangeEnd: dateRangeEnd,
          medicalInsuranceId: medicalInsuranceId,
          medicalInsurancePlanId: medicalInsurancePlanId
        });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/turnosmedicos/doctor/currentavailability?Id=' +
            id +
            '&DateRangeInit=' +
            moment(dateRangeInit).format('YYYY-MM-DDThh:mm:ss') +
            '&DateRangeEnd=' +
            moment(dateRangeEnd).add(6, 'month').format('YYYY-MM-DDThh:mm:ss') +
            '&MedicalInsuranceId=' +
            medicalInsuranceId +
            '&MedicalInsurancePlanId=' +
            medicalInsurancePlanId,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + appState.Patient?.Token?.accessToken
            }
          }
        )
          .then(
            (response) => response.json() as Promise<Result<DoctorAvailability>>
          )
          .then((data: Result<DoctorAvailability>) => {
            dispatch({
              type: 'RECEIVE_DOCTOR_AVAILABILITY',
              DoctorAvailability: data.data
            });
          });
      }
    },
  RequestSpecialtyCurrentAvailability:
    (
      operationId: number,
      specialty: string,
      dateRangeInit: Date,
      dateRangeEnd: Date,
      medicalInsuranceId: number,
      medicalInsurancePlanId: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'REQUEST_SPECIALTY_AVAILABILITY',
          specialty: specialty,
          dateRangeInit: dateRangeInit,
          dateRangeEnd: dateRangeEnd,
          medicalInsuranceId: medicalInsuranceId,
          medicalInsurancePlanId: medicalInsurancePlanId
        });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/turnosmedicos/specialty/currentavailability?Specialty=' +
            specialty +
            '&DateRangeInit=' +
            moment(dateRangeInit).format('YYYY-MM-DDThh:mm:ss') +
            '&DateRangeEnd=' +
            moment(dateRangeEnd).add(6, 'month').format('YYYY-MM-DDThh:mm:ss') +
            '&MedicalInsuranceId=' +
            medicalInsuranceId +
            '&MedicalInsurancePlanId=' +
            medicalInsurancePlanId,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + appState.Patient?.Token?.accessToken
            }
          }
        )
          .then((response) => {
            console.log(response);
            let Error: ErrorType | undefined = undefined;
            if (response.status >= 400) {
              switch (response.status) {
                case 401:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage: 'No Autorizado'
                  };
                  break;
                case 500:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage:
                      'Ocurrio un error inesperado al consultar por el recurso'
                  };
                  break;
                default:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage: 'Error inesperado'
                  };
              }
              throw Error;
            } else {
              return response.json() as Promise<Result<DoctorAvailability[]>>;
            }
          })
          .then((data: Result<DoctorAvailability[]>) => {
            console.log('data', data);
            dispatch({
              type: 'RECEIVE_SPECIALTY_AVAILABILITY',
              SpecialtyAvailability: data.data
            });
          })
          .catch((error: ErrorType) => {
            dispatch({ type: 'OPERATION_FAIL', Error: error });
          });
      }
    }
};
