import { MedicalAppointment } from './../../models/MedicalAppointmentModel';
import { Patient } from './../../models/PatientModel';
import {
  Store,
  createStore,
  combineReducers,
  compose,
  applyMiddleware
} from 'redux';

import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { AppState, reducers } from '../reducer';

export const configureStore = (
  history: History,
  initialState?: AppState
): Store<AppState> => {
  const middleware = [thunk, routerMiddleware(history)];

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  const enhancers = [];
  const windowIfDefined =
    typeof window === 'undefined' ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  return store;
};
