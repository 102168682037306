import {
  MedicalAppointmentResponse,
  MedicalAppointmentsResponse,
  NewMedicalAppointment
} from './../../models/MedicalAppointmentModel';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';
import {
  CanceledMedicalAppointmentAction,
  CancelMedicalAppointmentAction,
  ConfirmedMedicalAppointmentAction,
  ConfirmMedicalAppointmentAction,
  CreatedMedicalAppointmentAction,
  CreateMedicalAppointmentAction,
  ReceiveMedicalAppointmentAction,
  RequestMedicalAppointmentAction
} from '../actions/medicalAppointmentsActions';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import medicalappointmentsPage from '../../pages/appointments/medicalappointments.page';

type KnownAction =
  | RequestMedicalAppointmentAction
  | ReceiveMedicalAppointmentAction
  | ConfirmMedicalAppointmentAction
  | ConfirmedMedicalAppointmentAction
  | CancelMedicalAppointmentAction
  | CreateMedicalAppointmentAction
  | CreatedMedicalAppointmentAction
  | CanceledMedicalAppointmentAction;

export interface MedicalAppointmentState {
  readonly loading: boolean;
  readonly MedicalAppointments: MedicalAppointment[] | undefined;
}

export const initialMedialAppointmentState: MedicalAppointmentState = {
  loading: false,
  MedicalAppointments: undefined
};

export const reducer: Reducer<MedicalAppointmentState> = (
  state: MedicalAppointmentState | undefined,
  incomingAction: Action
): MedicalAppointmentState => {
  if (state === undefined) {
    return initialMedialAppointmentState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'RECEIVE_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        MedicalAppointments: action.MedicalAppointments,
        loading: false
      };
    }
    case 'CONFIRM_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'CONFIRMED_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        MedicalAppointments: state.MedicalAppointments?.map(
          (medicalappoinment: MedicalAppointment) =>
            medicalappoinment.id === action.MedicalAppointment?.id
              ? action.MedicalAppointment
              : medicalappoinment
        ),
        loading: false
      };
    }
    case 'CANCEL_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'CANCELED_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        MedicalAppointments: state.MedicalAppointments?.filter(
          (medicalAppoinment: MedicalAppointment) =>
            medicalAppoinment.id === action.MedicalAppointment?.id
              ? false
              : true
        ),
        loading: false
      };
    }
    case 'CREATE_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'CREATED_MEDICAL_APPOINTMENT': {
      return {
        ...state,
        MedicalAppointments: state.MedicalAppointments
          ? [...state.MedicalAppointments, action.MedicalAppointment]
          : [...[], action.MedicalAppointment],
        loading: false
      };
    }
  }

  return state;
};

export const actionCreators = {
  RequestMedicalAppointments:
    (patientId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_MEDICAL_APPOINTMENT', patientId: patientId });
        // 'https://consultamedica.idbnar.com/api/dev/turnosmedicos/patient/'
        await fetch(
          'https://autogestion.test.cinme.com.ar/apis/medicalappointments/patient/' +
            patientId +
            '?PageSize=1000',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + appState.Patient?.Token?.accessToken
            }
          }
        )
          .then(
            (response) =>
              response.json() as Promise<MedicalAppointmentsResponse>
          )
          .then((data: MedicalAppointmentsResponse) => {
            dispatch({
              type: 'RECEIVE_MEDICAL_APPOINTMENT',
              MedicalAppointments: data.data
            });
          });
      }
    },
  ConfirmMedicalAppointments:
    (appointmentId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'CONFIRM_MEDICAL_APPOINTMENT',
          AppointmentId: appointmentId
        });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/turnosmedicos/' +
            appointmentId +
            '/confirm'
        )
          .then(
            (response) => response.json() as Promise<MedicalAppointmentResponse>
          )
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'CONFIRMED_MEDICAL_APPOINTMENT',
              MedicalAppointment: data.data
            });
          });
      }
    },
  CancelMedicalAppointments:
    (appointmentId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'CANCEL_MEDICAL_APPOINTMENT',
          AppointmentId: appointmentId
        });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/turnosmedicos/' +
            appointmentId +
            '/cancel'
        )
          .then(
            (response) => response.json() as Promise<MedicalAppointmentResponse>
          )
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'CANCELED_MEDICAL_APPOINTMENT',
              MedicalAppointment: data.data
            });
          });
      }
    },
  CreateMedicalAppointment:
    (newAppointment: NewMedicalAppointment): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'CREATE_MEDICAL_APPOINTMENT',
          NewMedicalAppointment: newAppointment
        });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/turnosmedicos/',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + appState.Patient?.Token?.accessToken
            },
            body: JSON.stringify(newAppointment)
          }
        )
          .then(
            (response) => response.json() as Promise<MedicalAppointmentResponse>
          )
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'CREATED_MEDICAL_APPOINTMENT',
              MedicalAppointment: data.data
            });
          });
      }
    }
};
