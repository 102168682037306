import { DoctorState } from './doctorReducer';
import * as MedicalAppointments from './medicalAppointmentReducer';
import { MedicalAppointmentState } from './medicalAppointmentReducer';
import * as Patients from './patientReducer';
import * as Doctors from './doctorReducer';
import { PatientState } from './patientReducer';

export interface AppState {
  readonly Patient: PatientState | undefined;
  readonly MedicalAppointments: MedicalAppointmentState | undefined;
  readonly Doctors: DoctorState | undefined;
}

export const reducers = {
  Patient: Patients.reducer,
  MedicalAppointments: MedicalAppointments.reducer,
  Doctors: Doctors.reducer
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => AppState): void;
}
