import React, { useEffect, useRef, useState } from 'react';
import './login.page.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as PatientStore from '../../redux/reducer/patientReducer';
import checkIcon from '../../images/check.svg';
import {
  Text,
  Link,
  ActionButton,
  MessageBarType,
  Label,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import { IIconProps, Separator, MessageBar } from '@fluentui/react';

import ichtysLogo from '../../images/icthysLogo.png';
import cinmeLogo from '../../images/Logocinme.png';
import { Button, Form } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { useNavigate, useParams } from 'react-router-dom';

export const VerificationCodePage = () => {
  const CurrentPatient = useSelector((state: AppState) => state.Patient);
  const [currentOperationId, setCurrentOperationId] = useState<number>();
  const { patientDocument } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pinInput = useRef<any>();
  const sendEmailIcon: IIconProps = { iconName: 'MailForward' };

  useEffect(() => {
    dispatch(mapDispatchToProps.RequestPatient(patientDocument));
    if (CurrentPatient?.VerificationPinResult === true) {
      navigate(`/patient/${CurrentPatient?.Patient?.id}//appointments/`);
    }
  }, [dispatch, CurrentPatient?.VerificationPinResult]);

  const handlerButtonClick = (pin: any) => {
    const OperationId = Math.floor(Math.random() * (999999 - 99 + 1)) + 99;
    setCurrentOperationId(OperationId);
    dispatch(
      mapDispatchToProps.VerifyPinAction(
        OperationId,
        CurrentPatient!.Patient!.id,
        Number(pin)
      )
    );
    pinInput.current.clear();
  };

  const SendPinConfirmation = (
    <div className="DivMessage">
      <div className="roundedBorders">
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
          Le hemos enviado un nuevo pin a su casilla de mail
        </MessageBar>
      </div>
    </div>
  );

  const VerificationPinFail = (
    <div className="DivMessage">
      <div className="roundedBorders">
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          El pin ingresado es incorrecto
        </MessageBar>
      </div>
    </div>
  );

  const PageBackground = (
    <div className="loginbackground">
      <h1 className="pageheader">
        Servicio de <strong>telemedicina</strong>
      </h1>
    </div>
  );

  const handleRequestNewPin = () => {
    dispatch(
      mapDispatchToProps.RequestNewPinAction(CurrentPatient!.Patient!.id)
    );
  };

  const LoginForm = (
    <>
      <div className="loginform">
        <div className="logos">
          <img className="logoCinme" src={cinmeLogo} alt="Cinme"></img>
        </div>

        {CurrentPatient?.SendNewPinSuccess && SendPinConfirmation}
        <label>Resultado: {CurrentPatient?.VerificationPinResult}</label>
        {CurrentPatient?.VerificationPinResult === false && VerificationPinFail}

        {CurrentPatient?.Patient?.isPinRecentlySent && (
          <p className="CheckText">
            <label className="checkIcon">
              <img src={checkIcon} alt=""></img>
            </label>
            Por ser su primer ingreso, le hemos enviado automaticamente el{' '}
            <strong>Pin de Autenticación</strong> a su casilla de mail
          </p>
        )}
        <Form>
          <h4>Ingrese su pin</h4>
          <PinInput
            length={4}
            initialValue=""
            onChange={(value, index) => {}}
            type="numeric"
            inputMode="numeric"
            secret={true}
            ref={pinInput}
            style={{ padding: '0px', fontSize: '30px' }}
            inputStyle={{
              borderColor: 'lightgray',
              margin: '3px',
              borderRadius: '10px'
            }}
            inputFocusStyle={{ borderColor: 'gray' }}
            onComplete={handlerButtonClick}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <Button
            className="btnEnvioCodigo"
            variant="primary"
            onClick={handlerButtonClick}
            disabled={CurrentPatient?.SendPinVerification}
          >
            {CurrentPatient?.SendPinVerification === false ? (
              'Ingresar'
            ) : (
              <Spinner size={SpinnerSize.small} />
            )}
          </Button>
        </Form>
        <Separator />
        <Label>¿Olvidaste tu pin?</Label>
        <ActionButton
          iconProps={sendEmailIcon}
          onClick={handleRequestNewPin}
          allowDisabledFocus
        >
          Enviarme un nuevo pin
        </ActionButton>
        <Separator />
        <label className="TextIchtys">
          Powered by <img className="logo" src={ichtysLogo} alt="Icthys"></img>
        </label>
      </div>
    </>
  );

  return (
    <>
      {PageBackground}
      {LoginForm}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.Patient
});

const mapDispatchToProps = {
  ...PatientStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationCodePage as any);
