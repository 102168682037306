import React from 'react';
import { Button, Card } from 'react-bootstrap';

//styles
import './nextMedicalAppointmentComponent.css';

//images
import { DoctorNameComponent } from './doctorNameComponent';
import { AppointmentDayComponent } from './appointmentDayComponent';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';
import { Label } from '@fluentui/react';

export interface IProps {
  appointment: MedicalAppointment;
}

export const NextMedicAppointmentComponent = (props: IProps) => {
  return (
    <Card>
      <Card.Body>
        <Card.Header className="headerRed">
          <Label>
            {props.appointment.visitTypeCode === 'EXTERNAL'
              ? 'PRESENCIAL'
              : 'TELECONSULTA'}
          </Label>
        </Card.Header>
        <Card.Text>
          <AppointmentDayComponent
            Date={props.appointment.appointmentDateStart}
          />{' '}
          <DoctorNameComponent appointment={props.appointment} />
          <div className="appointmentActions">
            <Button variant="danger">Cancelar</Button>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
