import React from 'react';
import { UserInfo } from './userinfoComponent';
import LogoIcthys from '../images/icthysLogo.png';
import LogoCinme from '../images/Logocinme.png';

interface Props {
  Title: string;
}

export const Header = (props: Props) => (
  <header>
    <img className="Logoicthys" src={LogoCinme} alt="Icthys S.A." />
    <label className="no_show_mobile">{props.Title}</label>
    <UserInfo />
  </header>
);
