import {
  Calendar,
  Callout,
  Checkbox,
  ChoiceGroup,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  defaultDatePickerStrings,
  DocumentCard,
  DocumentCardActivity,
  DocumentCardStatus,
  DocumentCardTitle,
  Dropdown,
  DropdownMenuItemType,
  FocusTrapZone,
  IBasePickerProps,
  IBasePickerSuggestionsProps,
  IChoiceGroupOption,
  IDocumentCardActivityPerson,
  IDropdownOption,
  IDropdownStyles,
  IInputProps,
  ILabelStyles,
  IPersonaProps,
  IPersonaSharedProps,
  IStackProps,
  IStackStyles,
  IStyleSet,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  TextField,
  Toggle,
  ValidationState
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { FormEvent, useState } from 'react';
import './Appointment.Form.Component.css';
import { AppState } from '../../redux/reducer';
import { useDispatch, useSelector, connect } from 'react-redux';
import { NewMedicalAppointment } from '../../models/MedicalAppointmentModel';
import { useBoolean } from '@fluentui/react-hooks';
//Stores
import * as MedialAppointmentStore from '../../redux/reducer/medicalAppointmentReducer';
import * as DoctorsStore from '../../redux/reducer/doctorReducer';
import { Doctor } from '../../models/DoctorModel';
import {
  AvailableAppointment,
  DoctorAvailability,
  DoctorAvailableDay,
  TimeStamp
} from '../../models/DoctorAvailabilityModel';
import Moment from 'react-moment';
import moment from 'moment';
import { ErrorType } from '../../models/FetchErrorModel';

interface DoctorShifts {
  index: number;
  doctor: Doctor | undefined;
  date: Date;
  shiftStart: TimeStamp;
  shiftEnd: TimeStamp;
  TelemedicineEnabled: boolean;
  OnSiteEnabled: boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 250 }
};

const stackTokens = { childrenGap: 50 };
const iconProps = { iconName: 'Calendar' };
const stackStyles: Partial<IStackStyles> = { root: { width: 250 } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 250 } }
};

const onFormatDate = (date?: Date): string => {
  return !date
    ? ''
    : date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
};

interface FormValidation {
  success: boolean;
  errors: string[];
}

const buttonStyles = { root: { marginRight: 8 } };

const DayPickerStrings = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Deciembre'
  ],

  shortMonths: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic'
  ],

  days: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado'
  ],

  shortDays: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],

  goToToday: 'Ir a hoy',
  weekNumberFormatString: 'Numero Semana {0}'
};

const medicalAssitanceOptions: IChoiceGroupOption[] = [
  {
    key: 'osde',
    //imageSrc: TestImages.choiceGroupPieUnselected,
    imageAlt: 'osde',
    //selectedImageSrc: TestImages.choiceGroupPieSelected,
    imageSize: { width: 32, height: 32 },
    text: 'OSDE - PLAN 310'
  }
];

export interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
}

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Doctores sugeridos',
  mostRecentlyUsedHeaderText: 'Doctores sugeridos',
  noResultsFoundText: 'No se encontraron doctores',
  loadingText: 'Cargando',
  showRemoveButtons: false,
  suggestionsAvailableAlertText: 'Sugerencia de doctores habilitada',
  suggestionsContainerAriaLabel: 'Doctores sugeridos'
};

export const AppointmentFormComponent = (props: IProps) => {
  moment.locale();
  const [currentOperationId, setCurrentOperationId] = useState<number>();
  const [submittedForm, setSubmittedForm] = useState<boolean>(false);
  const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
  >();
  const [selectedDoctor, setSelectedDoctor] = useState<
    IPersonaProps | undefined
  >();
  const [appointmentFromDate, setAppointmentFromDate] = useState<Date>(
    new Date()
  );
  const [appointmentToDate, setAppointmentToDate] = useState<Date>(new Date());
  const [appointmentSchedule, setAppointmentSchedule] = useState<
    IDropdownOption | undefined
  >();
  const [selectedVisitType, setSelectedVisitType] = useState<
    string | undefined
  >();
  const [specialtySelected, setSpecialtySelected] = useState<string>();

  const [searchAvailabilityBySpecialty, setSearchAvailabilityBySpecialty] =
    useState<boolean>(false);

  const [immediatelyAttention, setImmediatelyAttention] =
    useState<boolean>(false);

  const Specialties = useSelector(
    (state: AppState) => state.Doctors?.Specialties
  );

  const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] =
    useBoolean(false);
  const buttonContainerRef = React.useRef<HTMLDivElement>(null);

  const [
    dropDownOptionsavailableAppointments,
    setDropDownOptionsavailableAppointments
  ] = useState<IDropdownOption[]>([]);

  const [currentSchedule, setCurrentSchedule] = useState<DoctorAvailableDay>();

  const [currentAvailabilityDaySelected, setCurrentAvailabilityDaySelected] =
    useState<AvailableAppointment>();

  const [disabledDays, setDisabledDays] = useState<Date[]>([]);

  const Doctors = useSelector((state: AppState) => state.Doctors?.Doctors);
  const DoctorsErrors = useSelector(
    (state: AppState) => state.Doctors?.OperationError
  );
  const CurrentPatient = useSelector(
    (state: AppState) => state.Patient?.Patient
  );

  const CurrentDoctorAvailability = useSelector(
    (state: AppState) => state.Doctors?.DoctorAvailability
  );

  const dispatch = useDispatch();

  const CreateDoctor = (doctor: Doctor): IPersonaProps => {
    return {
      key: doctor.externalDBID,
      imageInitials: 'DC',
      text: doctor.nombre + ', ' + doctor.apellido,
      secondaryText: doctor.externalSpecialty,
      id: doctor.id.toString()
    };
  };

  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>([]);
  const [doctorsList, setDoctorsList] = useState<IPersonaProps[]>([]);

  const doctorsPicker = React.useRef(null);

  useEffect(() => {
    dispatch(mapDispatchToProps.RequestSpecialties());
    dispatch(mapDispatchToProps.RequestDoctors(''));
  }, [dispatch]);

  useEffect(() => {
    setMostRecentlyUsed(
      Doctors?.filter((doctor: Doctor) => doctor.externalDBID !== null).map(
        (doctor: Doctor) => CreateDoctor(doctor)
      ) || []
    );
  }, [Doctors]);

  useEffect(() => {
    const Days: Date[] = (
      CurrentDoctorAvailability?.availableDates?.filter(
        (date: AvailableAppointment) => (date.available ? false : true)
      ) || []
    ).map<Date>(
      (appointment: AvailableAppointment) => new Date(appointment.date)
    );

    setDisabledDays(Days);
  }, [CurrentDoctorAvailability]);

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
    dropdownItem: { height: 'auto' }
  };

  const ConstructDropdownAppointmentOption = (
    appointment: DoctorAvailableDay,
    key: number
  ): IDropdownOption => {
    const DropdownOption: IDropdownOption = {
      key: key,
      text:
        String(appointment.appointmentInit.hours).padStart(2, '0') +
        ':' +
        String(appointment.appointmentInit.minutes).padStart(2, '0') +
        ' - ' +
        String(appointment.appointmentEnd.hours).padStart(2, '0') +
        ':' +
        String(appointment.appointmentEnd.minutes).padStart(2, '0') +
        ' | ' +
        (appointment.onSiteAvailable && appointment.telemedicineAvailable
          ? 'Presencial / Telemedicina'
          : '') +
        (appointment.onSiteAvailable && !appointment.telemedicineAvailable
          ? ' Solo presencial'
          : '') +
        (appointment.telemedicineAvailable && !appointment.onSiteAvailable
          ? ' Solo telemedicina'
          : ''),
      disabled: !appointment.available
    };

    return DropdownOption;
  };

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      if (currentPersonas)
        filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return doctorsList.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return convertResultsToPromise(personasToReturn);
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = doctorsList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = doctorsList
        .slice(0, indexPeopleList)
        .concat(doctorsList.slice(indexPeopleList + 1));
      setDoctorsList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  const [immediateShifts, setImmediateShifts] = useState<DoctorShifts[]>([]);

  const GetNewAppointment = (): NewMedicalAppointment => {
    var newAppointment: NewMedicalAppointment;

    newAppointment = {
      doctorId: 0,
      patientId: 0,
      appointmentDateStart: new Date(),
      appointmentDateEnd: new Date(),
      specialtyId: '1',
      specialtyDescription: 'Cardiología',
      visitTypeCode: '',
      location: '',
      description: ''
      // appointmentExternalTenant: ''
    };

    return newAppointment;
  };

  const [newAppointment, setNewAppointment] = useState<NewMedicalAppointment>(
    GetNewAppointment()
  );

  const onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton onClick={HandleSaveForm} styles={buttonStyles}>
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={HandleCancelForm}>Cancelar</DefaultButton>
      </div>
    );
  };

  const ValidateAttribute = (): boolean => {
    var validationResult: boolean = true;

    var FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (!selectedDoctor?.key) {
      validationResult = false;
      FormValidation.errors.push(
        'Debe seleccionar el Médico con el cual desea solicitar el turno'
      );
    }

    if (!selectedVisitType) {
      validationResult = false;
      FormValidation.errors.push(
        'Debe seleccionar si desea un turno presencial o por teleconsulta'
      );
    }

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
  };

  const HandleSaveForm = () => {
    setSubmittedForm(true);
    if (ValidateAttribute() && CurrentPatient) {
      var AppointmentValues: NewMedicalAppointment;

      AppointmentValues = {
        appointmentDateStart: appointmentFromDate,
        appointmentDateEnd: appointmentToDate,
        doctorId: Number(selectedDoctor?.id || 0),
        patientId: CurrentPatient?.id,
        specialtyId: '1',
        specialtyDescription: 'Cardiología',
        visitTypeCode: selectedVisitType || 'EXTERNAL',
        location:
          (selectedVisitType || 'EXTERNAL') === 'EXTERNAL'
            ? 'Consultorio externo'
            : 'Teleconsulta',
        description: ''
        // appointmentExternalTenant: ''
      };

      console.log(AppointmentValues);

      dispatch(mapDispatchToProps.CreateMedicalAppointment(AppointmentValues));

      ResetForm();

      props.dismissPanel();
    }
  };

  const HandleCancelForm = () => {
    ResetForm();

    props.dismissPanel();
  };

  const ResetForm = () => {
    setSubmittedForm(false);
    setSelectedDoctor(undefined);
    setSelectedVisitType(undefined);
    setAppointmentFromDate(new Date());
    setAppointmentToDate(new Date());
    setAppointmentSchedule(undefined);
    setFormValidationStatus(undefined);
    setDropDownOptionsavailableAppointments([]);
    setCurrentAvailabilityDaySelected(undefined);
    setImmediatelyAttention(false);
  };

  // handle form data changes
  const SelectDoctor = (doctors: IPersonaProps[] | undefined): void => {
    if (doctors && doctors.length > 0) {
      setSelectedDoctor(doctors[0]);
      dispatch(
        mapDispatchToProps.RequestDoctorCurrentAvailability(
          Number(doctors[0]?.key),
          new Date(),
          new Date(),
          0,
          0
        )
      );
    } else {
      ResetForm();
    }
  };

  const SelectVisitType = (
    event: FormEvent<HTMLDivElement>,
    item: IDropdownOption | undefined
  ): void => {
    setSelectedVisitType(String(item?.key) || 'EXTERNAL');
  };

  const onChangeAppointmentDateFieldValue = (date: Date | null | undefined) => {
    if (date) {
      const DateStart: Date | null | undefined = new Date(date);
      const DateEnd: Date | null | undefined = new Date(date);

      const DayAvailability: AvailableAppointment | undefined =
        CurrentDoctorAvailability?.availableDates?.find(
          (appointmentDate: AvailableAppointment) =>
            new Date(appointmentDate.date).getDate() ===
            new Date(DateStart).getDate()
              ? true
              : false
        );

      if (DayAvailability && DayAvailability.availableAppointments) {
        const list = DayAvailability.availableAppointments.map<IDropdownOption>(
          (appointment: DoctorAvailableDay, index: number) =>
            ConstructDropdownAppointmentOption(appointment, index)
        );

        setCurrentAvailabilityDaySelected(DayAvailability);
        setDropDownOptionsavailableAppointments(list || []);
      }

      // if (appointmentSchedule && appointmentSchedule.key) {
      //   var schedule: string[] = String(appointmentSchedule.key).split(':');

      //   DateStart?.setHours(Number(schedule[0]));
      //   DateStart?.setMinutes(Number(schedule[1]));
      //   DateStart?.setSeconds(0);

      //   console.log(DateStart);

      //   DateEnd?.setHours(Number(schedule[0]));
      //   DateEnd?.setMinutes(Number(schedule[1]) + 15);
      //   DateEnd?.setSeconds(0);

      //   setAppointmentFromDate(DateStart || new Date());
      //   setAppointmentToDate(DateEnd || new Date());
      // } else {
      if (date !== appointmentFromDate) {
        setAppointmentFromDate(date || new Date());
        setAppointmentToDate(date || new Date());
      }
      // }
      hideCalendar();
    }
  };
  const onChangeAppointmentTimeFieldValue = (
    event: FormEvent<HTMLDivElement>,
    item: IDropdownOption | undefined
  ): void => {
    setAppointmentSchedule(item);
    if (item && currentAvailabilityDaySelected) {
      const schedule: DoctorAvailableDay =
        currentAvailabilityDaySelected.availableAppointments[Number(item.key)];

      setCurrentSchedule(schedule);

      if (schedule.onSiteAvailable && !schedule.telemedicineAvailable)
        setSelectedVisitType('EXTERNAL');
      if (!schedule.onSiteAvailable && schedule.telemedicineAvailable)
        setSelectedVisitType('EXTERNAL_PHONE');

      var date: Date = new Date(appointmentFromDate);
      if (date) {
        const DateStart: Date | null | undefined = new Date(date);
        const DateEnd: Date | null | undefined = new Date(date);

        DateStart?.setHours(schedule.appointmentInit.hours);
        DateStart?.setMinutes(schedule.appointmentInit.minutes);
        DateStart?.setSeconds(0);

        DateEnd?.setHours(schedule.appointmentEnd.hours);
        DateEnd?.setMinutes(schedule.appointmentEnd.minutes);
        DateEnd?.setSeconds(0);

        setAppointmentFromDate(DateStart || new Date());
        setAppointmentToDate(DateEnd || new Date());
      }
    }
  };

  const onSelectClosedAppointmentTimeFieldValue = (
    item: DoctorShifts,
    index: number
  ): void => {
    if (item) {
      const DayAvailability: AvailableAppointment | undefined =
        CurrentDoctorAvailability?.availableDates?.find(
          (date: AvailableAppointment) =>
            new Date(date.date).getDate() === new Date(item.date).getDate()
              ? true
              : false
        );

      if (DayAvailability) {
        const schedule: DoctorAvailableDay | undefined =
          DayAvailability.availableAppointments[item.index];
        if (schedule) {
          setCurrentSchedule(schedule);

          if (schedule.onSiteAvailable && !schedule.telemedicineAvailable)
            setSelectedVisitType('EXTERNAL');
          if (!schedule.onSiteAvailable && schedule.telemedicineAvailable)
            setSelectedVisitType('EXTERNAL_PHONE');

          const CurrentShiftSelected: IDropdownOption =
            ConstructDropdownAppointmentOption(schedule, item.index);

          setAppointmentSchedule(CurrentShiftSelected);
          setCurrentAvailabilityDaySelected(DayAvailability);

          var date: Date = new Date(item.date);
          if (date) {
            const DateStart: Date | null | undefined = new Date(date);
            const DateEnd: Date | null | undefined = new Date(date);

            DateStart?.setHours(schedule.appointmentInit.hours);
            DateStart?.setMinutes(schedule.appointmentInit.minutes);
            DateStart?.setSeconds(0);

            console.log(DateStart);

            DateEnd?.setHours(schedule.appointmentEnd.hours);
            DateEnd?.setMinutes(schedule.appointmentEnd.minutes);
            DateEnd?.setSeconds(0);

            setAppointmentFromDate(DateStart || new Date());
            setAppointmentToDate(DateEnd || new Date());
          }
        }
      }
    }
  };

  const onChangeSpecialtyFieldValue = (
    event: FormEvent<HTMLDivElement>,
    item: IDropdownOption | undefined
  ): void => {
    if (item) {
      // set OperationId
      const OperationId = Math.floor(Math.random() * (999999 - 99 + 1)) + 99;
      setSpecialtySelected(item.text);
      setCurrentOperationId(OperationId);
      dispatch(
        mapDispatchToProps.RequestSpecialtyCurrentAvailability(
          OperationId,
          item.text,
          new Date(),
          new Date(),
          0,
          0
        )
      );
    }
  };

  const _onCheckedChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    setCurrentAvailabilityDaySelected(undefined);
    setImmediatelyAttention(isChecked!);
    if (!isChecked) setAppointmentFromDate(new Date());
    else setImmediateShifts(GetDoctorClosestShifts());
  };

  const VisitOptions: IDropdownOption[] = [
    { key: 'EXTERNAL', text: 'De forma presencial' },
    { key: 'EXTERNAL_PHONE', text: 'Remoto por Telemedicina' }
  ];

  function MakeDropOption(stringValue: string): IDropdownOption {
    return {
      text: stringValue,
      key: stringValue
    };
  }

  const SpecialtyOptions: IDropdownOption[] =
    Specialties?.map((specialty: string) => MakeDropOption(specialty)) || [];

  const GetMaxDate = (): Date => {
    var maxDate: Date = new Date();
    maxDate.setMonth(new Date().getMonth() + 6);
    return maxDate;
  };

  const GetDoctorClosestShifts = (): DoctorShifts[] => {
    let AvailableShifts: DoctorShifts[] = [];
    var count: number = 1;

    CurrentDoctorAvailability?.availableDates.forEach(
      (shift: AvailableAppointment) => {
        shift.availableAppointments.forEach(
          (appointment: DoctorAvailableDay, index: number) => {
            if (shift.available && appointment.available && count <= 10) {
              console.log(count);
              AvailableShifts = [
                ...AvailableShifts,
                {
                  doctor: Doctors?.find((doctor: Doctor) =>
                    doctor.externalDBID === CurrentDoctorAvailability.doctor.id
                      ? true
                      : false
                  ),
                  date: shift.date,
                  OnSiteEnabled: appointment.onSiteAvailable,
                  TelemedicineEnabled: appointment.telemedicineAvailable,
                  shiftStart: appointment.appointmentInit,
                  shiftEnd: appointment.appointmentEnd,
                  index: index
                }
              ];
              count++;
            }
          }
        );
      }
    );

    return AvailableShifts;
  };

  const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 }
  };

  const CreateCardDoctor = (doctor: Doctor): IDocumentCardActivityPerson[] => {
    return [...[], { name: doctor.name, profileImageSrc: '', initials: 'CB' }];
  };

  const formFields = (
    <Stack {...columnProps}>
      {console.log('render from fields')}
      <ChoiceGroup
        label="Seleccione su cobertura médica"
        defaultSelectedKey="osde"
        options={medicalAssitanceOptions}
      />
      <Pivot aria-label="Basic Pivot Example">
        <PivotItem
          headerText="Médico"
          headerButtonProps={{
            'data-order': 1,
            'data-title': 'My Files Title'
          }}
          onClick={() => setSearchAvailabilityBySpecialty(false)}
          className="mt-2"
        >
          <Label>Doctor</Label>
          <NormalPeoplePicker
            // eslint-disable-next-line react/jsx-no-bind
            onResolveSuggestions={onFilterChanged}
            // eslint-disable-next-line react/jsx-no-bind
            onEmptyResolveSuggestions={returnMostRecentlyUsed}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionProps}
            className={'ms-PeoplePicker'}
            key={'normal'}
            // eslint-disable-next-line react/jsx-no-bind
            onRemoveSuggestion={onRemoveSuggestion}
            onValidateInput={validateInput}
            selectionAriaLabel={'Selected contacts'}
            removeButtonAriaLabel={'Remove'}
            itemLimit={1}
            inputProps={{
              onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
                console.log('onBlur called'),
              onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
                console.log('onFocus called'),
              'aria-label': 'Doctor'
            }}
            componentRef={doctorsPicker}
            onInputChange={onInputChange}
            onChange={SelectDoctor}
          />
          {CurrentDoctorAvailability?.availableWeekDays.length === 0 && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
              className="mt-3"
            >
              El médico seleccionado no tiene disponibilidad
            </MessageBar>
          )}
        </PivotItem>
        <PivotItem
          headerText="Especialidad"
          onClick={() => setSearchAvailabilityBySpecialty(true)}
          className="mt-2"
        >
          <Dropdown
            placeholder="Seleccione una especialidad"
            label="Especialidad"
            options={SpecialtyOptions}
            onChange={onChangeSpecialtyFieldValue}
            styles={dropdownStyles}
            errorMessage={
              submittedForm === true &&
              searchAvailabilityBySpecialty === true &&
              !selectedVisitType
                ? 'Debe seleccionar una especialidad'
                : undefined
            }
            required
          />
        </PivotItem>
      </Pivot>
      {selectedDoctor && CurrentDoctorAvailability?.availableWeekDays.length && (
        <>
          <Label>Fecha</Label>
          {immediatelyAttention === false && (
            <>
              <div ref={buttonContainerRef}>
                <DefaultButton
                  onClick={toggleShowCalendar}
                  className="normalbutton"
                  text={
                    !currentAvailabilityDaySelected
                      ? 'Fecha de antención'
                      : new Date(
                          currentAvailabilityDaySelected.date
                        ).toLocaleDateString('fr-BE')
                  }
                />
              </div>
              {showCalendar && (
                <Callout
                  isBeakVisible={false}
                  gapSpace={0}
                  doNotLayer={false}
                  target={buttonContainerRef}
                  onDismiss={hideCalendar}
                  setInitialFocus
                >
                  <FocusTrapZone isClickableOutsideFocusTrap>
                    <Calendar
                      strings={DayPickerStrings}
                      onSelectDate={onChangeAppointmentDateFieldValue}
                      restrictedDates={disabledDays}
                      minDate={new Date()}
                      maxDate={GetMaxDate()}
                      isMonthPickerVisible={true}
                      value={appointmentFromDate}
                    />
                  </FocusTrapZone>
                </Callout>
              )}
            </>
          )}
          <Checkbox
            label="Necesito ser antendido lo antes posible"
            checked={immediatelyAttention}
            onChange={_onCheckedChange}
          />
        </>
      )}

      {dropDownOptionsavailableAppointments.length > 0 &&
        currentAvailabilityDaySelected &&
        !immediatelyAttention && (
          <>
            {console.log('render horario')}
            <Dropdown
              placeholder="Seleccione una opción"
              label="Horario"
              options={dropDownOptionsavailableAppointments}
              onChange={onChangeAppointmentTimeFieldValue}
              styles={dropdownStyles}
              errorMessage={
                submittedForm === true && !currentSchedule
                  ? 'Debe seleccionar un horario para la consulta'
                  : undefined
              }
              required
            />
          </>
        )}
      {immediatelyAttention && (
        <>
          <label className="ms-label">Próximos turnos disponibles</label>
        </>
      )}
      {immediatelyAttention && (
        <div className="shiftsRoll">
          {immediateShifts.map((shifts: DoctorShifts, index: number) => (
            <>
              {console.log('render card')}
              <DocumentCard
                aria-label="Lunes 17 19:30 a 20:00"
                key={shifts.index}
                className={
                  appointmentSchedule?.key === shifts.index ? 'Active' : ''
                }
                onClick={() =>
                  onSelectClosedAppointmentTimeFieldValue(shifts, index)
                }
              >
                <DocumentCardTitle
                  title={moment(shifts.date).format('dddd DD MMMM')}
                  shouldTruncate
                  showAsSecondaryTitle
                />
                <DocumentCardTitle
                  title={
                    String(shifts.shiftStart.hours).padStart(2, '0') +
                    ':' +
                    String(shifts.shiftStart.minutes).padStart(2, '0') +
                    ' - ' +
                    String(shifts.shiftEnd.hours).padStart(2, '0') +
                    ':' +
                    String(shifts.shiftEnd.minutes).padStart(2, '0') +
                    ''
                  }
                  shouldTruncate
                />
                <DocumentCardStatus
                  statusIcon=""
                  status={
                    (shifts.OnSiteEnabled && shifts.TelemedicineEnabled
                      ? 'Presencial / Telemedicina'
                      : '') +
                    (shifts.OnSiteEnabled && !shifts.TelemedicineEnabled
                      ? ' Solo presencial'
                      : '') +
                    (shifts.TelemedicineEnabled && !shifts.OnSiteEnabled
                      ? ' Solo telemedicina'
                      : '')
                  }
                />
                <DocumentCardActivity
                  activity=""
                  people={[
                    {
                      name: CurrentDoctorAvailability?.doctor.name || '',
                      profileImageSrc: '',
                      initials: 'DC'
                    }
                  ]}
                />
              </DocumentCard>
            </>
          ))}
        </div>
      )}
      {currentSchedule &&
        currentSchedule.onSiteAvailable &&
        currentSchedule.telemedicineAvailable && (
          <Dropdown
            placeholder="Seleccione una opción"
            label="Cómo desea realizar su consulta?"
            options={VisitOptions}
            onChange={SelectVisitType}
            styles={dropdownStyles}
            errorMessage={
              submittedForm === true && !selectedVisitType
                ? 'Debe seleccionar un tipo de visita'
                : undefined
            }
            required
            selectedKey={selectedVisitType}
          />
        )}
    </Stack>
  );

  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.dismissPanel}
      headerText="Nuevo Turno"
      type={PanelType.smallFixedFar}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
      // Stretch panel content to fill the available height so the footer is positioned
      // at the bottom of the page
      isFooterAtBottom={true}
    >
      {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          Revise la información suministrada:
          <ul>
            {FormValidationStatus?.errors.map(
              (Error: string, index: number) => (
                <li key={index}>{Error}</li>
              )
            )}
          </ul>
        </MessageBar>
      )}
      {currentOperationId &&
        DoctorsErrors?.find(
          (error: ErrorType) => error.OperarionId === currentOperationId
        ) && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            Ocurrio un error:
            <ul>
              {DoctorsErrors?.filter(
                (error: ErrorType) => error.OperarionId === currentOperationId
              ).map((Error: ErrorType, index: number) => (
                <li key={index}>{Error.ErrorMesage}</li>
              ))}
            </ul>
          </MessageBar>
        )}
      {formFields}
    </Panel>
  );
};

function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[] | undefined
) {
  if (possibleDupes)
    return personas.filter(
      (persona) => !listContainsPersona(persona, possibleDupes)
    );
  else return personas;
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

function convertResultsToPromise(
  results: IPersonaProps[]
): Promise<IPersonaProps[]> {
  return new Promise<IPersonaProps[]>((resolve, reject) =>
    setTimeout(() => resolve(results), 2000)
  );
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

function onInputChange(input: string): string {
  // const outlookRegEx = /<.*>/g;
  // const emailAddress = outlookRegEx.exec(input);

  // if (emailAddress && emailAddress[0]) {
  //   return emailAddress[0].substring(1, emailAddress[0].length - 1);
  // }

  console.log(input);
  return input;
}

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalAppointments,
  ...state.Doctors
});

const mapDispatchToProps = {
  ...MedialAppointmentStore.actionCreators,
  ...DoctorsStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentFormComponent as any);
