import React, { ChangeEvent, useEffect, useState } from 'react';
import './login.page.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as PatientStore from '../../redux/reducer/patientReducer';

import ichtysLogo from '../../images/icthysLogo.png';
import cinmeLogo from '../../images/Logocinme.png';
import { Button, Form } from 'react-bootstrap';

import { useSelector, useDispatch, connect } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { useNavigate } from 'react-router-dom';
import { MessageBar, MessageBarType, Separator } from '@fluentui/react';

export const LoginPage = () => {
  const dispatch = useDispatch();

  const CurrentPatient = useSelector((state: AppState) => state.Patient);

  const navigate = useNavigate();

  const [patientDocument, setPatientDocument] = useState<string>();

  const handlerInputPatientDocument = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setPatientDocument(event.currentTarget.value);
  };

  useEffect(() => {
    if (CurrentPatient && CurrentPatient.PatientExist === true) {
      //alert(patientDocument);
      navigate(patientDocument + '/verification');
    }
  }, [CurrentPatient?.Patient]);

  const handleKeypress = (e: any) => {
    if (e.key === 'Enter') {
      handlerButtonclick();
    }
  };

  const handlerButtonclick = () => {
    if (patientDocument)
      dispatch(mapDispatchToProps.RequestPatient(patientDocument));
  };

  return (
    <div className="loginbackground">
      <h1 className="pageheader">
        Servicio de <strong>telemedicina</strong>
      </h1>
      <div className="loginform">
        <div className="logos">
          <img className="logoCinme" src={cinmeLogo} alt="Cinme"></img>
        </div>
        <h2 className="header">¡Bienvenido!</h2>
        <p>Por favor ingrese su DNI para identificarse</p>
        {CurrentPatient?.PatientExist === false && (
          <div className="roundedBorders">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Usted no se encuentra registrado como paciente en nuestro
              servicio. <br />
              <strong>por favor verifique el documento ingresado</strong>
            </MessageBar>
          </div>
        )}
        <div>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>DNI</Form.Label>
            <Form.Control
              type="dni"
              inputMode="numeric"
              style={{ fontSize: 25 }}
              placeholder="Ingrese su DNI"
              onChange={handlerInputPatientDocument}
              maxLength={8}
              onKeyPress={handleKeypress}
            />
          </Form.Group>
          <Button
            className="btnEnvioCodigo"
            variant="primary"
            disabled={patientDocument ? false : true}
            type="button"
            onClick={handlerButtonclick}
          >
            Ingresar
          </Button>
        </div>
        <Separator />
        <label className="TextIchtys">
          Powered by <img className="logo" src={ichtysLogo} alt="Icthys"></img>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.Patient
});

const mapDispatchToProps = {
  ...PatientStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage as any);
