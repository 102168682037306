import {
  ReceivePatientAction,
  RequestPatientAction,
  RequestNewPinAction,
  SendNewPinSuccessAction,
  ReceiveEmptyPatientAction,
  VerifyPinAction,
  VerifyPinFailAction,
  VerifyPinSuccessAction,
  PatientExitAction,
  OperationFailAction,
  VerifyTokenExpirationSuccessAction
} from './../actions/patientsActions';
import { Action, Reducer } from 'redux';
import { Patient } from '../../models/PatientModel';

import { AppThunkAction } from './';
import { Token } from '../../models/Token';
import { ErrorType } from '../../models/FetchErrorModel';

type KnownAction =
  | RequestPatientAction
  | ReceivePatientAction
  | RequestNewPinAction
  | SendNewPinSuccessAction
  | ReceiveEmptyPatientAction
  | PatientExitAction
  | VerifyPinAction
  | VerifyPinFailAction
  | VerifyTokenExpirationSuccessAction
  | OperationFailAction
  | VerifyPinSuccessAction;

export interface PatientState {
  readonly loading: boolean;
  readonly Patient: Patient | undefined;
  readonly PatientExist: boolean | undefined;
  readonly RequestNewPin: boolean;
  readonly SendNewPinSuccess: boolean;
  readonly SendPinVerification: boolean;
  readonly VerificationPinResult: boolean | undefined;
  readonly Token: Token | undefined;
  readonly OperationError: ErrorType[];
}

const initialPatientState: PatientState = {
  loading: false,
  Patient: undefined,
  PatientExist: undefined,
  RequestNewPin: false,
  SendNewPinSuccess: false,
  SendPinVerification: false,
  VerificationPinResult: undefined,
  Token: undefined,
  OperationError: []
};

const LoadTokenFromStore = (): Token | null => {
  const json = localStorage.getItem('Authentication');
  if (json) {
    return JSON.parse(json);
  }
  return null;
};

export const reducer: Reducer<PatientState> = (
  state: PatientState | undefined,
  incomingAction: Action
): PatientState => {
  if (state === undefined) {
    return initialPatientState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_PATIENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'RECEIVE_PATIENT': {
      return {
        ...state,
        Patient: action.Patient,
        loading: false,
        PatientExist: true,
        OperationError: []
      };
    }
    case 'RECEIVE_EMPTY_PATIENT': {
      return {
        ...state,
        Patient: undefined,
        loading: false,
        PatientExist: false
      };
    }
    case 'REQUEST_NEW_PIN_PATIENT': {
      return {
        ...state,
        RequestNewPin: true,
        SendNewPinSuccess: false
      };
    }
    case 'SEND_NEW_PIN_SUCCESS': {
      return {
        ...state,
        RequestNewPin: false,
        SendNewPinSuccess: true
      };
    }
    case 'VERIFY_PIN': {
      return {
        ...state,
        SendPinVerification: true,
        VerificationPinResult: undefined
      };
    }
    case 'VERIFY_PIN_FAIL': {
      return {
        ...state,
        RequestNewPin: false,
        SendPinVerification: false,
        VerificationPinResult: false,
        OperationError: []
      };
    }
    case 'VERIFY_PIN_SUCCESS': {
      localStorage.setItem('Authentication', JSON.stringify(action.token));
      return {
        ...state,
        SendPinVerification: false,
        VerificationPinResult: true,
        Token: action.token,
        OperationError: []
      };
    }
    case 'PATIENT_EXIT_ACTION': {
      localStorage.removeItem('Authentication');
      return {
        ...state,
        Patient: undefined,
        PatientExist: undefined,
        RequestNewPin: false,
        SendPinVerification: false,
        VerificationPinResult: undefined,
        OperationError: []
      };
    }
    case 'VERIFY_TOKEN_EXPIRATION_SUCCESS': {
      return {
        ...state,
        Token: action.token,
        OperationError: []
      };
    }
    case 'OPERATION_FAIL': {
      return {
        ...state,
        OperationError: [...state.OperationError, action.Error]
      };
    }
  }

  return state;
};

export const actionCreators = {
  RequestPatient:
    (patientdocument: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (
        (appState && !appState.Patient) ||
        !appState.Patient!.loading ||
        (appState.Patient!.Patient &&
          appState.Patient!.Patient?.documento !== patientdocument)
      ) {
        dispatch({ type: 'REQUEST_PATIENT' });
        await fetch(
          'https://autogestion.test.cinme.com.ar/apis/patients/Pacientes/3/' +
            patientdocument
        )
          .then((response) => {
            switch (response.status) {
              case 204:
                dispatch({ type: 'RECEIVE_EMPTY_PATIENT' });
                return undefined;
              case 200:
                return response.json() as Promise<Patient>;
              default:
                return undefined;
            }
          })
          .then((data: Patient | undefined) => {
            if (data !== undefined)
              dispatch({ type: 'RECEIVE_PATIENT', Patient: data });
          });
      }
    },
  RequestPatientById:
    (patientId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_PATIENT' });
        await fetch(
          'https://autogestion.test.cinme.com.ar/apis/patients/Pacientes/' +
            patientId
        )
          .then((response) => response.json() as Promise<Patient>)
          .then((data: Patient) => {
            dispatch({ type: 'RECEIVE_PATIENT', Patient: data });
          });
      }
    },
  RequestNewPinAction:
    (patientId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_NEW_PIN_PATIENT', PatientId: patientId });
        await fetch(
          'https://autogestion.test.cinme.com.ar/apis/patients/Pacientes/' +
            patientId +
            '/regeneratepin'
        )
          .then((response) => response.json() as Promise<Patient>)
          .then((data: Patient) => {
            dispatch({ type: 'SEND_NEW_PIN_SUCCESS' });
          });
      }
    },
  VerifyPinAction:
    (
      operationId: number,
      patientId: number,
      pin: number
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'VERIFY_PIN', PatientId: patientId, Pin: pin });
        await fetch(
          'https://autogestion.test.cinme.com.ar/apis/patients/Pacientes/' +
            patientId +
            '/VerificarPin/' +
            pin
        )
          .then((response) => {
            let Error: ErrorType | undefined = undefined;
            if (response.status >= 400) {
              switch (response.status) {
                case 401:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage: 'No Autorizado'
                  };
                  break;
                case 409:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage: 'Datos invalidos'
                  };
                  break;
                case 500:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage:
                      'Ocurrio un error inesperado al consultar por el recurso'
                  };
                  break;
                default:
                  Error = {
                    OperarionId: operationId,
                    ErrorCode: response.status,
                    ErrorMesage: 'Error inesperado'
                  };
              }
              throw Error;
            } else {
              return response.json() as Promise<Token>;
            }
          })
          .then((data: Token) => {
            if (data) {
              dispatch({ type: 'VERIFY_PIN_SUCCESS', token: data });
            } else {
              dispatch({ type: 'VERIFY_PIN_FAIL' });
            }
          })
          .catch((error: ErrorType) => {
            dispatch({ type: 'VERIFY_PIN_FAIL' });
            dispatch({ type: 'OPERATION_FAIL', Error: error });
          });
      }
    },
  PatientExitAction:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'PATIENT_EXIT_ACTION' });
      }
    },
  VerifyAuthenticationToken:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        const AuthenticationToken: Token | null = LoadTokenFromStore();
        console.log(AuthenticationToken);
        if (AuthenticationToken) {
          dispatch({
            type: 'VERIFY_TOKEN_EXPIRATION_SUCCESS',
            token: AuthenticationToken
          });
        }
      }
    }
};
